import { GraphQLResult, GraphQLQuery } from "@aws-amplify/api";
import {
  CreateVariables,
  GroupBulkTrashVariables,
  GroupUpdateVariables,
  Option,
} from "./../models/app";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilters,
  setListing,
  setSelected,
  setSelectedFilters,
} from "../store/ducks/group";
import { HeadCell } from "../models/dataTable";
import useApp from "./useApp";
import { Group, ReservationStatus } from "../models";
import useGroupSetting from "./useGroupSetting";
import { GroupGetVariables, GroupListingVariables } from "../models/app";
import { CreateGroupInput } from "../models/GQL_API";
import { updateGroup } from "../graphql/mutations";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const useResource = (listingName: string, singleName: string) => {
  const statusesListing: ReservationStatus[] = useSelector(
    (state: any) => state.statuses.listing
  );
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const { groupSettingsCreate } = useGroupSetting(
    "groupSettings",
    "groupSetting"
  );

  async function fetch(params: GroupListingVariables) {
    const { searchText, startIndex, limit, accountID } = params;

    try {
      const listing = await DataStore.query(
        Group as any,
        (model: any) => {
          model.accountID("eq", accountID).deleted("eq", "0");

          if (searchText.length > 0)
            model.message("contains", searchText.toLowerCase());

          return model;
        },
        {
          page: startIndex / limit,
          limit: limit,
          sort: (s) => s.createdAt(SortDirection.DESCENDING),
        }
      );

      dispatch(setListing(listing));
      // dispatch(setFilters(listing.map((model: any) => model.name)));

      return listing;
    } catch (err: Error | any) {
      showError(err.message);
    }
  }

  /**
   * Get Resource Name
   *
   * @param id id: string
   *
   * @returns string
   */
  const getName = (params: GroupGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Group) => model.id === id);

      return model ? model.name : "";
    }

    return "";
  };

  const getColor = (params: GroupGetVariables) => {
    const { id, listing } = params;

    if (listing.length > 0) {
      const model = listing.find((model: Group) => model.id === id);

      return model ? model.color : "";
    }

    return "";
  };

  async function get(params: GroupGetVariables) {
    const { id, listing } = params;

    try {
      const single: Group | undefined =
        listing.length === 0
          ? await DataStore.query(Group as any, id)
          : listing.find(
              (model: Group) => model.id === id || model.name === id
            );

      return single;
    } catch (err) {
      showError(err);
    }
  }

  async function create(params: CreateVariables) {
    const { userID, userName, data } = params;

    // if (!data.accountID) {
    //   const error = new Error(`Cannot create ${singleName} without accountID`);
    //   return showError(error);
    // }

    try {
      // if (
      //   (data.upgradeGroup === data.downgradeGroup &&
      //     data.downgradeGroup !== "-1") ||
      //   (data.upgradeGroup === data.downgradeGroup &&
      //     data.upgradeGroup !== "-1")
      // ) {
      //   const error = new Error(
      //     "Upgrade and downgrade group must be different"
      //   );
      //   return showError(error);
      // }
      const createInput: CreateGroupInput = {
        accountID: data.accountID,
        name: data.name.toLowerCase(),
        numToUpgrade: data.numToUpgrade ? parseInt(data.numToUpgrade) : 0,
        numToDowngrade: data.numToDowngrade ? parseInt(data.numToDowngrade) : 0,
        upgradeGroup: data.upgradeGroup ? data.upgradeGroup : "-1",
        downgradeGroup: data.downgradeGroup ? data.downgradeGroup : "-1",
        deleted: "0",
        createdAt: new Date().toLocaleString(),
        createdByID: userID,
        createdByName: userName,
      };

      if (data.description) createInput.description = data.description;
      if (data.color) createInput.color = data.color;
      if (data.requireApproval)
        createInput.requireApproval = data.requireApproval;
      if (data.status) createInput.status = data.status;
      if (data.agents) createInput.agents = data.agents;

      const model: Group = await DataStore.save(new Group(createInput as any));

      const status: ReservationStatus | undefined = statusesListing.find(
        (status: any) => status.name === "normal"
      );

      // if (!status) throw new Error("Cannot find status");

      const groupSettingData: any = {
        groupID: model.id,
        statusID: status?.id,
        needsVerification: true,
        needsDeposit: true,
        isDefault: true,
        amount: 500,
      };

      await groupSettingsCreate({ userID, userName, data: groupSettingData });

      showConfirm(`New ${singleName} has been created successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function update(params: GroupUpdateVariables) {
    const { id, listing, data } = params;
    try {
      const original = await get({ id, listing });
      // if (
      //   (data.upgradeGroup === data.downgradeGroup &&
      //     data.downgradeGroup !== "-1") ||
      //   (data.upgradeGroup === data.downgradeGroup &&
      //     data.upgradeGroup !== "-1")
      // ) {
      //   const error = new Error(
      //     "Upgrade and downgrade group must be different"
      //   );
      //   return showError(error);
      // }

      // if (data.numToDowngrade < 0 || data.numToUpgrade < 0) {
      //   showError("Number to upgrade or downgrade must be greater than 0");
      //   return;
      // }

      await DataStore.save(
        Group.copyOf(original!, (updated) => {
          updated.name =
            data.name !== undefined ? data.name.toLowerCase() : original!.name;
          updated.upgradeGroup =
            data.upgradeGroup !== undefined
              ? data.upgradeGroup
              : original!.upgradeGroup;
          updated.downgradeGroup =
            data.downgradeGroup !== undefined
              ? data.downgradeGroup
              : original!.downgradeGroup;
          updated.numToUpgrade =
            data.numToUpgrade !== undefined
              ? parseInt(data.numToUpgrade)
              : original!.numToUpgrade;
          updated.numToDowngrade =
            data.numToDowngrade !== undefined
              ? parseInt(data.numToDowngrade)
              : original!.numToDowngrade;
          updated.description =
            data.description !== undefined
              ? data.description
              : original!.description;
          updated.color =
            data.groupColor !== undefined ? data.groupColor : original!.color;
          updated.requireApproval =
            data.requireApproval !== undefined
              ? data.requireApproval
              : original!.requireApproval;
          updated.status =
            data.status !== undefined ? data.status : original!.status;
          updated.agents =
            data.agents !== undefined ? data.agents : original!.agents;
        })
      );

      showConfirm(`${singleName} has been updated successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function updateOnline(params: GroupUpdateVariables) {
    const { id, listing, data } = params;
    try {
      const original = await get({ id, listing });

      const updated: GraphQLResult<any> = await API.graphql({
        query: updateGroup,
        variables: {
          input: {
            id: original!.id,
            name:
              data.name !== undefined
                ? data.name.toLowerCase()
                : original!.name,
            upgradeGroup:
              data.upgradeGroup !== undefined
                ? data.upgradeGroup
                : original!.upgradeGroup,
            downgradeGroup:
              data.downgradeGroup !== undefined
                ? data.downgradeGroup
                : original!.downgradeGroup,
            numToUpgrade:
              data.numToUpgrade !== undefined
                ? parseInt(data.numToUpgrade)
                : original!.numToUpgrade,
            numToDowngrade:
              data.numToDowngrade !== undefined
                ? parseInt(data.numToDowngrade)
                : original!.numToDowngrade,
            description:
              data.description !== undefined
                ? data.description
                : original!.description,
            color:
              data.groupColor !== undefined ? data.groupColor : original!.color,
            requireApproval:
              data.requireApproval !== undefined
                ? data.requireApproval
                : original!.requireApproval,
            status: data.status !== undefined ? data.status : original!.status,
            agents: data.agents !== undefined ? data.agents : original!.agents,
          },
        },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);
      return updated.data.updateGroup;
    } catch (err) {
      showError(err);
      throw err;
    }
  }

  async function trash(params: GroupGetVariables) {
    try {
      const original = await get(params);

      await DataStore.save(
        Group.copyOf(original!, (updated) => {
          updated.deleted = "1";
        })
      );

      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: GroupBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  async function remove(params: GroupGetVariables) {
    const { id, listing } = params;

    try {
      await DataStore.delete(id as any);

      dispatch(setListing(listing.filter((model: any) => model.id !== id)));

      showConfirm(`${singleName} has been deleted successfully`);
    } catch (err: Error | any) {
      showError(err);
    }
  }

  function options(listing: Group[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["name"];

  const api: any = {};

  api[`${listingName}Model`] = Group as any;
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}UpdateOnline`] = updateOnline;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}Delete`] = remove;
  api[`${listingName}GetName`] = getName;
  api[`${listingName}GetColor`] = getColor;
  api[`${listingName}ChangeListing`] = (listing: Group[]) => {
    if (listing) {
      dispatch(setListing(listing));
      dispatch(setFilters(listing.map((model: any) => model.name)));
    }
  };
  api[`${listingName}ChangeSelected`] = (id: string) =>
    dispatch(setSelected(id));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));

  return api;
};

export default useResource;
