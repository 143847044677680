export const listUsersChannelsCustom = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        channel
      }
      nextToken
      startedAt
      scannedCount
      count
      __typename
    }
  }
`;
