export const DEPLOYMENT_VERSION = "3";

export enum Directions {
  RTL = "rtl",
  LTR = "ltr",
}

export enum Languages {
  ENGLISH = "en",
  ARABIC = "ar",
}

export enum Pages {
  LOGIN = "",
  REGISTER = "register",
  VERIFY = "verify",
  FORGOT_PASSWORD = "forget-password",
  DASHBOARD = "overview",
  ACCOUNTS = "accounts",
  ADMINS = "admins",
  LANGUAGES = "languages",
  FEATURES = "features",
  PAGES = "pages",
  MEDIA_LIBRARY = "media-library",
  MESSAGES = "messages",
  MENUS = "menus",
  POSTS = "posts",
  SETTINGS = "settings",
  SLIDES = "slides",
  COURSES = "courses",
  MODULES = "modules",
  QUIZZES = "quizzes",
  QUIZ_QUESTIONS = "quiz-questions",
  QUESTIONS = "questions",
  QUESTIONS_TYPES = "questions-types",
  GROUPS = "groups",
  GROUP_SETTINGS = "group-settings",
  INTERESTS = "interests",
  BOOKINGS = "bookings",
  BOOKINGS_LIST = "bookings-history",
  FLAGS = "flags",
  GUESTS = "guests",
  GUESTS_PROFILE = "guests-profile",
  CONCEPTS = "concepts",
  PAYMOB = "paymob",
  BRANCHES = "branches",
  RESERVATION_STATUS = "statuses",
  TIME_SLOTS = "time-slots",
  AREAS = "areas",
  COMMENTS = "comments",
  PLAN_ITEMS = "plan-items",
  TABLES = "tables",
  TIMELINES = "timelines",
  SERVING_AREAS = "serving-areas",
  OBJECTS = "objects",
  CUSTOMER_STATS = "customerStats",
  FLOOR_EDITOR = "floor-editor",
  SOCIAL_LINKS = "social-links",
  CALL_CENTER = "call-center",
  CALL_CENTER_SETTINGS = "call-center-settings",
  BOOKINGS_TABLES = "bookings-tables",
  RESET_PASSWORD = "reset-password",
  NOTIFICATION_SETTINGS = "notification-settings",
  NOTIFICATIONS = "notifications",
  Mobile_NOTIFICATIONS = "mobileNotifications",
  BOOKING_CONFIRMATION = "reservations",
  ADMIN_ROLES = "admin-roles",
  ADMIN_GROUPS = "admin-groups",
  PAYMENT = "Payment",
  PAYMENT_DASHBOARD = "transactionsDashboard",
  TRANSACTIONS_REPORT = "transactions",
  PAYMENT_STATS = "paymentStats",
  BOOKING_GUEST = "bookingGuests",
  Parent_Concepts = "parentconcepts",
  // Online Ordering
  CATEGORIES = "categories",
  MENU_ITEMS = "menuItems",
  PRICES = "prices",
  CHOICES = "choices",
  CHOICE_GROUPS = "choiceGroups",
  ORDER_STATUSES = "orderStatuses",
  ONLINE_ORDER = "onlineOrders",
  KPI_REPORT = "KPIReport",
  DISPATCHERS = "dispatchers",
  WAITERS = "waiters",
  WAITER_ORDER = "waiterOrder",
  MAIN_CATEGORIES = "mainCategories",
  SHIFTS = "shifts",
}

export enum Orders {
  ASC = "asc",
  DSC = "desc",
}

export enum FormActions {
  ADD = "Add",
  EDIT = "Edit",
}

export enum QuestionTypes {
  TRUE_FALSE = "True False",
  CHOOSE = "Choose",
  CHOOSE_IMAGE = "Choose with Image",
  TEXT_IMAGE = "Text with Image",
  OPTIONS_IMAGE = "Options as Image",
  COMPLETE_IMAGE = "Complete with Image",
}

export enum TimelineActions {
  CHANGE_FACEBOOK = "Facebook link",
  CHANGE_INSTAGRAM = "Instagram link",
  CHANGE_NAME = "name",
  CHANGE_ADDRESS = "address",
  CHANGE_GROUP = "Group",
  CHANGE_EMAIL = "Email",
  CHANGE_VERIFICATION_STATUS = "Verify Guest",
  ADD_INTEREST = "Add Customer Interests",
  REMOVE_INTEREST = "Remove Customer Interests",
  ADD_FLAG = "Add Customer Flags",
  REMOVE_FLAG = "Remove Customer Flags",
  CHANGE_PHONE_NUMBER = "Phone Number",
  DEPOSIT_PAYMENT_SUCCESS = "Deposit Payment Success",
  DEPOSIT_PAYMENT_FAILED = "Deposit Payment Failed",
  DEPOSIT_PAYMENT_REFUND = "Deposit Refund Success",
  DELETE_COMMENT = "Delete Comment",
}

export enum Channels {
  TELEPHONE_CHANNEL = "telephone",
  ANDROID_CHANNEL = "android",
  IPHONE_CHANNEL = "iphone",
  WEB_CHANNEL = "web",
  WEBSITE = "website",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  WHATSAPP = "whatsapp",
  MOBILE_APP = "mobile app",
}

export enum userChannels {
  Apple = "Apple",
  Google = "Google",
  Phone = "Phone",
}

export enum ResourceTypes {
  CUSTOMER = "customer",
  BOOKING = "booking",
}

export enum BookingTimelineActions {
  TIME_SLOT = "Update time slot",
  STATUS = "Update Booking Status",
  TABLE = "Update Booking Table",
  DATE = "Update Booking Date",
  GUEST_COUNT = "Update Guest Count",
  RESERVATION_EMAIL = "Send Reservation Email",
}

export enum AuthErrors {
  USER_EXISTS = "UsernameExistsException",
}

export enum AdminsGroups {
  ADMIN = "admin",
}

export enum AdminsConstants {
  ADMINS_API = "AdminQueries",
}

export enum AdminsApiPaths {
  LIST_USERS = "/listUsers",
  LIST_USERS_IN_GROUP = "/listUsersInGroup",
  LIST_GROUPS_FOR_USER = "/listGroupsForUser",
  GET_USER = "/getUser",
  LIST_GROUPS = "/listGroups",
  ADD_USER_TO_GROUP = "/addUserToGroup",
  REMOVE_USER_FROM_GROUP = "/removeUserFromGroup",
  DISABLE_USER = "/disableUser",
  ENABLE_USER = "/enableUser",
  ADMIN_DELETE_USER = "/adminDeleteUser",
}

export const NOTIFICATION_CHANNELS = [
  { label: "email", value: "email" },
  { label: "sms", value: "sms" },
];

export const ENCRYPTION_KEY =
  "123456dasflasdldfl789asjdflkj3fadsfsdlfds0123456";

export enum ADMIN_ROLES {
  SETTINGS = "settings_access",
  DASHBOARD = "dashboard_access",
  ADMINS = "admins_access",
  VIEW_RESERVATIONS = "view_reservation_access",
  CREATE_RESERVATION = "create_reservation_access",
  CANCEL_RESERVATION = "cancel_reservation_access",
  CREATE_GUEST = "create_guest_access",
  EDIT_GUEST = "edit_guest_access",
  EDIT_VIP = "edit_vip_access",
  LOCK_TABLE = "lock_table_access",
  EDIT_LAYOUT = "edit_layout_access",
  TRANSACTION_REPORT = "transaction_report",
  PAYMENT_DASHBOARD = "payment_dashboard",
  REFUND_PAYMENT = "refund_payment",
  PAYMOB_SETTINGS = "paymob_settings",
  CALL_CENTER = "call_center",
  WAITER = "waiter",
  KITCHEN = "kitchen",
}

export enum LOCAL_STORAGE {
  SYNC_BOOKINGS = "sync-bookings",
  SYNC_GUESTS = "sync-guests",
  SYNC_GUEST_STATS = "sync-guest-stats",
  SELECTED_CONCEPT = "selected-concept",
  BOOKING_DATE_RANGE = "booking-date-range",
  BOOKING_GUEST = "booking-guest",
  BOOKING_REFRESH = "booking-refresh",
  BOOKING_SYNC_DURATION = "booking-sync-duration",
  BOOKING_DATE = "booking-date",
  GUESTS_SEARCH = "guests-search",
  VERSION = "version",
  VERSION_CHECKING = "version_checking",
  LAST_VISIT = "lastVisit",
}

export enum DATE_PICKER_TYPES {
  SINGLE = "single",
  RANGE = "range",
}

export enum MODAL_TYPES {
  DATE_PICKER = "date-picker",
  BOOKING = "booking",
  EDIT_BOOKING = "edit-booking",
  VIEW_OLD_BOOKING = "view-old-booking",
  GUEST_STATS = "guest-stats",
}

export enum Transaction_TYPES {
  DEPOSIT = "deposit",
  REFUND = "refund",
}

export enum BOOKING_STATUS {
  RESERVED = "Reserved",
  CONFIRMED = "Confirmed",
  CHECK_IN = "Check In",
  CANCELLED = "Cancelled",
  CLOSED = "Closed",
  WAITING_LIST = "Waiting List",
  PENDING_REVIEW = "Pending Review",
  Fully_Booked = "Fully Booked",
}

export enum OrderStatus {
  pending = "pending",
  sent = "sent to restaurant",
  failed = "failed to sent to restaurant",
  confirmed = "confirmed",
  cancelled = "cancelled",
  pendingCancellation = "pending Cancellation",
  updated = "updated",
  acceptedByWaiter = "accepted by waiter",
  sentToKitchen = "sent To Kitchen",
  acceptedByKitchen = "accepted by kitchen",
}

export enum AccountDomain {
  BAKY_DOMAIN = "anyware",
  TROPITEL_DOMAIN = "tropitel",
}
