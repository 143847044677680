import { NOTIFICATION_CHANNELS, Pages } from "../constants/enums";
import useLocalization from "./useLocalization";
import { FormFieldsVariables } from "../models/app";
import { capitalizeSentence, isLocalhost } from "../helpers/utils";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";

const useFormFields = (params: FormFieldsVariables) => {
  const conceptsListing = useSelector((state: any) => state.concepts.listing);
  const options = conceptsListing.map((concept: any) => ({
    value: concept.id,
    label: concept.name,
  }));
  const {
    slug,
    model,
    languagesOptions,
    featuresOptions,
    areasOptions,
    tablesOptions,
    groupsOptions,
    statusesOptions,
    flagsOptions,
    interestsOptions,
    adminsOptions,
    adminRolesOptions,
    categoriesOptions,
    menuItemsOptions,
    choicesOptions,
    choiceGroupsOptions,
    areasSelected,
    tablesSelected,
    statusesSelected,
    adminsCognitoGroups,
  } = params;
  const { strings } = useLocalization();

  const loginFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];

  const registerFields = () => [
    {
      name: "name",
      label: "Full Name",
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];

  const adminFields = () => [
    {
      name: "name",
      label: "Full Name",
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "group",
      label: "Admin Group",
      type: "select",
      autoFocus: false,
      defaultValue:
        slug === Pages.ADMINS
          ? adminsCognitoGroups[0].value
          : adminsCognitoGroups[1].value,
      options: adminsCognitoGroups,
    },
  ];
  const forgetPasswordFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
  ];

  const resetPasswordFields = () => [
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "code",
      label: strings.code,
      type: "textField",
      autoFocus: false,
      defaultValue: "",
    },
    {
      name: "newPassword",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];

  const accountFields = () => [
    {
      name: "domain",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.domain : "",
      // disabled: true,
    },
    // {
    //   name: "siteTitle",
    //   label: "Site Title",
    //   type: "textField",
    //   autoFocus: false,
    //   defaultValue: model ? model.siteTitle : "",
    // },
    // {
    //   name: "tagline",
    //   label: "Tagline",
    //   type: "textField",
    //   autoFocus: false,
    //   defaultValue: model ? model.tagline : "",
    // },
    // {
    //   name: "description",
    //   label: "Description",
    //   type: "textField",
    //   autoFocus: false,
    //   defaultValue: model ? model.description : "",
    // },
    // {
    //   name: "siteAddress",
    //   label: "Site Address",
    //   type: "textField",
    //   autoFocus: false,
    //   defaultValue: model ? model.siteAddress : "",
    // },
    {
      name: "defaultLanguage",
      label: "Default Language",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.defaultLanguage
        : languagesOptions
        ? languagesOptions[0].value
        : "-1",
      options: languagesOptions,
    },
    {
      name: "sponsorImage",
      label: "Sponsor Image",
      type: "upload",
      autoFocus: false,
      defaultValue: model ? model.sponsorImage : "",
    },
  ];

  const callCenterFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
  ];

  const adminRoleFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
  ];

  const adminGroupsFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
  ];

  const tableFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "label",
      label: "Label",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.label : "",
    },
    {
      name: "width",
      label: "Width",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.width : "",
    },
    {
      name: "height",
      label: "Height",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.height : "",
    },
    {
      name: "capacity",
      label: "Max Covers",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.capacity : "",
    },
  ];

  const userFields = () => {
    let defaultGroup = "";

    if (groupsOptions) {
      const group = groupsOptions.find(
        (item: any) => item.label === "normal" || item.label === "Normal"
      );

      defaultGroup = group ? group.value : "";
    }

    if (model) defaultGroup = model.group;

    return [
      {
        name: "given_name",
        label: "First Name",
        type: "textField",
        autoFocus: true,
        defaultValue: model ? model.given_name : "",
      },
      {
        name: "family_name",
        label: "Last Name",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.family_name : "",
      },
      {
        name: "nickname",
        label: "Nickname",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.nickname : "",
      },
      {
        name: "address",
        label: "Address",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.address : "",
      },
      {
        name: "phone_number",
        label: "Phone Number",
        type: "phoneNumber",
        autoFocus: false,
        defaultValue: model ? model.phone_number : "",
      },
      {
        name: "birthdate",
        label: "Birth date",
        type: "date",
        autoFocus: false,
        defaultValue: model ? model.birthdate : "",
      },
      {
        name: "email",
        label: "Email",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.email : "",
      },
      {
        name: "group",
        label: "Group",
        type: "select",
        autoFocus: false,
        defaultValue: defaultGroup,
        options: groupsOptions,
      },
      {
        name: "website",
        label: "Website URL",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.website : "",
      },
      {
        name: "facebook",
        label: "Facebook URL",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.facebook : "",
      },
      {
        name: "instagram",
        label: "Instagram URL",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.instagram : "",
      },
    ];
  };

  const areaFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "isDefault",
      label: "IS Default",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.isDefault : "",
    },
  ];

  const conceptFields = () => [
    {
      name: "name",
      label: "Concept Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      multiline: true,
      defaultValue: model ? model.description : "",
    },
    {
      name: "email",
      label: "Email",
      type: "textField",
      autoFocus: false,
      multiline: true,
      defaultValue: model ? model.email : "",
    },
    {
      name: "location",
      label: "Location",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.location : "",
    },
    {
      name: "type",
      label: "Type",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.type : "",
    },
    {
      name: "image",
      label: "Image",
      type: "upload",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
    {
      name: "logo",
      label: "Logo",
      type: "upload",
      autoFocus: false,
      defaultValue: model ? model.logo : "",
    },
    {
      name: "album",
      label: "Album",
      type: "upload",
      autoFocus: false,
      defaultValue: model ? model.album : "",
    },
    {
      name: "areas",
      label: "Areas",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.areas) : new Set(),
      options: [
        { value: "Indoor", label: "Indoor" },
        { value: "Outdoor", label: "Outdoor" },
      ],
    },
    {
      name: "seatTypes",
      label: "Seat Types",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.seatTypes) : new Set(),
      options: [
        { value: "Dinning Table", label: "Dinning Table" },
        { value: "High Table", label: "High Table" },
      ],
    },
    {
      name: "operating",
      label: "Concept Operation",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.operating : false,
      // options: [{value:"Operating",label:"Operating"}],
    },
    {
      name: "eventDeposit",
      label: "Event Deposit",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.eventDeposit : "",
    },
    {
      name: "merchantID",
      label: "Merchant ID",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.merchantID : "",
    },
    {
      name: "merchantUsername",
      label: "Merchant Username",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.merchantUsername : "",
    },
    {
      name: "merchantPassword",
      label: "Merchant Password",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.merchantPassword : "",
    },
    {
      name: "merchantIntegrationNumber",
      label: "Integration Number",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.merchantIntegrationNumber : "",
    },
    {
      name: "merchantAPIKey",
      label: "Merchant API Key",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.merchantAPIKey : "",
    },

    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "longitude",
      label: "Longitude",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.longitude : "",
    },
    {
      name: "latitude",
      label: "Latitude",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.latitude : "",
    },
  ];

  const payMobFields = () => [
    {
      name: "merchantID",
      label: "Merchant ID", //this is paymob ID
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.merchantID : "",
    },
    {
      name: "merchantIntegrationNumber",
      label: "Integration Number",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.merchantIntegrationNumber : "",
    },
    {
      name: "merchantAPIKey",
      label: "Merchant API Key",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.merchantAPIKey : "",
    },
    {
      name: "exportInterval",
      label: "Export Interval",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.exportInterval : "",
    },
  ];

  const featureFields = () => [
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "name",
      label: "Feature Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.name : "",
    },
    {
      name: "icon",
      label: "Feature Icon",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.icon : "",
    },
    {
      name: "slug",
      label: "Feature Slug",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.slug : "",
    },
    {
      name: "parent",
      label: "Parent",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.parent
          ? model.parent
          : featuresOptions
          ? featuresOptions[0].value
          : "-1"
        : featuresOptions
        ? featuresOptions[0].value
        : "-1",
      options: featuresOptions,
    },
    {
      name: "private",
      label: "Private",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.private : false,
    },
  ];

  const languageFields = () => [
    {
      name: "name",
      label: "Language Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "code",
      label: "Language Code",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
  ];

  const dispatchersFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "email",
      label: strings.email,
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
    {
      name: "password",
      label: strings.password,
      type: "password",
      autoFocus: false,
      defaultValue: "",
    },
  ];
  const socialLinkFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "icon",
      label: "Icon",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
    {
      name: "slug",
      label: "URL",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.code : "",
    },
  ];

  const groupFields = (numToUpgradeWatch: any, numToDowngradeWatch: any) => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "description",
      label: "Description",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.description : "",
    },
    {
      name: "numToUpgrade",
      label: 'Number of "Check In" to upgrade group',
      type: "number",
      autoFocus: false,
      defaultValue: model
        ? model.numToUpgrade
          ? model.numToUpgrade.toString()
          : "0"
        : "0",
    },
    {
      name: "upgradeGroup",
      label: "Upgrade Group",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.upgradeGroup
          ? model.upgradeGroup
          : groupsOptions
          ? groupsOptions[0].value
          : "-1"
        : "-1",
      options: groupsOptions,
      disabled: numToUpgradeWatch === "0",
    },
    {
      name: "numToDowngrade",
      label: 'Number of "No Show" to upgrade group',
      type: "number",
      autoFocus: false,
      defaultValue: model
        ? model.numToDowngrade
          ? model.numToDowngrade.toString()
          : "0"
        : "0",
    },
    {
      name: "downgradeGroup",
      label: "Downgrade Group",
      type: "select",
      autoFocus: false,
      defaultValue: model
        ? model.downgradeGroup
          ? model.downgradeGroup
          : groupsOptions
          ? groupsOptions[0].value
          : "-1"
        : "-1",
      options: groupsOptions,
      disabled: numToDowngradeWatch === "0",
    },
    {
      name: "requireApproval",
      label: "Require Approval",
      type: "checkbox",
      autoFocus: false,
      defaultChecked: model ? model.requireApproval : false,
    },
  ];

  const notificationsFields = (channelsWatch?: any) => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "channel",
      label: "Channel",
      type: "select",
      autoFocus: false,
      defaultValue: model ? model.channel : NOTIFICATION_CHANNELS[0].value,
      options: NOTIFICATION_CHANNELS,
    },
    {
      name: "from",
      label: "From",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.from : "",
    },
    {
      name: "message",
      label: "Message",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.message : "",
      hidden: channelsWatch === "email",
    },
    {
      name: "enabled",
      label: "Enabled",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? (model.enabled ? model.enabled : false) : false,
    },
    {
      name: "autoSend",
      label: "Auto Send",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? (model.autoSend ? model.autoSend : false) : false,
    },
  ];

  const statusFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "category",
      label: "Category",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.category : "",
    },
  ];

  const flagFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "icon",
      label: "Icon",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.icon : "",
    },
  ];

  const interestFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
  ];

  const groupSettingFields = () => [
    {
      name: "groupID",
      label: "Group",
      type: "select",
      autoFocus: true,
      defaultValue: model
        ? model.groupID
          ? model.groupID
          : groupsOptions
          ? groupsOptions[0].value
          : "-1"
        : "-1",
      options: groupsOptions,
    },
    {
      name: "statusID",
      label: "Reservation Status",
      type: "select",
      autoFocus: false,
      defaultValue: model ? model.statusID : statusesSelected,
      options: statusesOptions,
    },
    {
      name: "needsVerification",
      label: "Needs Verification",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.needsVerification : false,
    },
    {
      name: "needsDeposit",
      label: "Needs Deposit",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.needsDeposit : false,
    },
    {
      name: "isDefault",
      label: "Is Default",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.isDefault : false,
    },
    {
      name: "amount",
      label: "Needs Deposit",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.amount : "0",
    },
  ];

  const planItemFields = () => [
    {
      name: "areaID",
      label: "Area",
      type: "select",
      autoFocus: true,
      defaultValue: model ? model.areaID : areasSelected,
      options: areasOptions,
    },
    {
      name: "xPosition",
      label: "X Position",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.xPosition : "0",
    },
    {
      name: "yPosition",
      label: "Y Position",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.yPosition : "0",
    },
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.name : "",
    },
    {
      name: "isReserved",
      label: "Is Reserved",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.isReserved : false,
    },
    {
      name: "locked",
      label: "Is Locked",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.locked : false,
    },
    {
      name: "table",
      label: "Table",
      type: "select",
      autoFocus: false,
      defaultValue: model ? model.table : tablesSelected,
      options: tablesOptions,
    },
    {
      name: "object",
      label: "Object",
      type: "select",
      autoFocus: false,
      defaultValue: model ? model.object : "-1",
      options: [],
    },
  ];

  const timeSlotFields = () => [
    {
      name: "name",
      label: "Time Interval",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "friendlyName",
      label: "Friendly Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.friendlyName : "",
    },
  ];

  const userCheckboxes = () => [
    {
      name: "interests",
      label: "Interests",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: new Set(),
      options: interestsOptions,
    },
    {
      name: "flags",
      label: "Flags",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: new Set(),
      options: flagsOptions,
    },
  ];

  const accountCheckboxes = () =>
    isLocalhost
      ? [
          {
            name: "languages",
            label: "Languages",
            type: "checkboxes",
            autoFocus: false,
            defaultValue: model ? new Set(model.languages) : new Set(),
            options: languagesOptions,
          },
          {
            name: "features",
            label: "Features",
            type: "checkboxes",
            autoFocus: false,
            defaultValue: model ? new Set(model.features) : new Set(),
            options: featuresOptions,
          },
        ]
      : [];

  const adminGroupsCheckboxes = () => [
    {
      name: "roles",
      label: "Admin Roles",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.roles) : new Set(),
      options: adminRolesOptions,
    },
    {
      name: "users",
      label: "Admin Users",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.users) : new Set(),
      options: adminsOptions,
    },
  ];

  const menuItemCheckboxes = () => [
    {
      name: "choiceGroups",
      label: "Choice Groups",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.choiceGroups) : new Set(),
      options: choiceGroupsOptions,
    },
  ];

  const choiceGroupsCheckboxes = () => {
    return [
      {
        name: "choices",
        label: "Choices",
        type: "checkboxes",
        autoFocus: false,
        defaultValue: model ? new Set(model.choices) : new Set(),
        options: choicesOptions,
      },
    ];
  };

  const bookingConfirmationFields = () => [
    {
      name: "email",
      type: "textField",
      autoFocus: true,
      defaultValue: "",
    },
  ];

  const transactionFields = () => [
    {
      name: "transactionID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.transactionID : "",
    },
    {
      name: "guestName",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.guestName : "",
    },
    {
      name: "guestPhone",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.guestPhone : "",
    },
    {
      name: "guestID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.guestID : "",
    },
    {
      name: "guestGroup",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.guestGroup : "",
    },
    {
      name: "bookingID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.bookingID : "",
    },
    {
      name: "amount_cents",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.amount_cents : "",
    },
    {
      name: "status",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.status : "",
    },
    {
      name: "type",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.type : "",
    },
    {
      name: "timeSlots",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.timeSlots : "",
    },
    {
      name: "tables",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.tables : "",
    },
    {
      name: "conceptID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.conceptID : "",
    },
    {
      name: "currency",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.currency : "",
    },
    {
      name: "refund",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.refund : "",
    },

    {
      name: "orderID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.orderID : "",
    },
    {
      name: "ownerID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.ownerID : "",
    },
    {
      name: "refunded_amount_cents",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.refunded_amount_cents : "",
    },
  ];

  const paymentStatFields = () => [
    {
      name: "accountID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.accountID : "",
    },
    {
      name: "year",
      type: "number",
      autoFocus: true,
      defaultValue: model ? model.year : "",
    },
    {
      name: "month",
      type: "number",
      autoFocus: true,
      defaultValue: model ? model.month : 1,
    },
    {
      name: "earning",
      type: "number",
      autoFocus: true,
      defaultValue: model ? model.earning : "",
    },
    {
      name: "refund",
      type: "number",
      autoFocus: true,
      defaultValue: model ? model.refund : "",
    },
    {
      name: "earningTrxCount",
      type: "number",
      autoFocus: true,
      defaultValue: model ? model.earningTrxCount : "",
    },
    {
      name: "refundTrxCount",
      type: "number",
      autoFocus: true,
      defaultValue: model ? model.refundTrxCount : 0,
    },
  ];

  const bookingGuestFields = () => [
    {
      name: "bookingID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.bookingID : "",
    },
    {
      name: "guestName",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.guestName : "",
    },
    {
      name: "paidAmount",
      type: "number",
      autoFocus: true,
      defaultValue: model ? model.paidAmount : "",
    },
    {
      name: "paymentAmount",
      type: "number",
      autoFocus: true,
      defaultValue: model ? model.paymentAmount : "",
    },
    {
      name: "transactionID",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.transactionID : "",
    },
  ];

  /* Call Center and Online Ordering Forms */
  const categoryFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? capitalizeSentence(model.name) : "",
    },
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.precedence : "",
    },
  ];

  const mainCategoryFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? capitalizeSentence(model.name) : "",
    },
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "openTime",
      label: "Open Time",
      type: "time",
      autoFocus: false,
      defaultValue: model ? model.openTime : "",
    },
    {
      name: "closeTime",
      label: "Close Time",
      type: "time",
      autoFocus: false,
      defaultValue: model ? model.closeTime : "",
    },
  ];
  const shiftFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? capitalizeSentence(model.name) : "",
    },
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "startTime",
      label: "Start Time",
      type: "time",
      autoFocus: false,
      defaultValue: model ? model.startTime : "",
    },
    {
      name: "endTime",
      label: "End Time",
      type: "time",
      autoFocus: false,
      defaultValue: model ? model.endTime : "",
    },
  ];

  const menuItemFields = () => {
    let defaultCategoryID: string = "-1";

    if (categoriesOptions && categoriesOptions.length > 0) {
      defaultCategoryID = categoriesOptions[0].value;
    }

    return [
      {
        name: "name",
        label: "Name",
        type: "textField",
        autoFocus: true,
        defaultValue: model ? model.name : "",
      },
      {
        name: "description",
        label: "Description",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.description : "",
      },
      {
        name: "categoryID",
        label: "Category",
        type: "select",
        autoFocus: false,
        defaultValue: model ? model.categoryID : defaultCategoryID,
        options: categoriesOptions,
      },
      {
        name: "quantity",
        label: "Stock Quantity",
        type: "number",
        autoFocus: false,
        defaultValue: model ? model.quantity : 0,
      },
      {
        name: "outOfStock",
        label: "Out of Stock",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model
          ? model.outOfStock
            ? model.outOfStock
            : false
          : false,
      },
      {
        name: "hasLimitedQuantity",
        label: "Has Limited Quantity",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model
          ? model.hasLimitedQuantity
            ? model.hasLimitedQuantity
            : false
          : false,
      },
      {
        name: "autoRestock",
        label: "Auto Restock Daily",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model
          ? model.autoRestock
            ? model.autoRestock
            : false
          : false,
      },
      {
        name: "restockQuantity",
        label: "Restock Quantity",
        type: "number",
        autoFocus: false,
        defaultValue: model ? model.restockQuantity : 0,
      },
    ];
  };

  const choiceFields = () => {
    let defaultChoiceGroupID: string = "-1";

    if (choiceGroupsOptions && choiceGroupsOptions.length > 0) {
      defaultChoiceGroupID = choiceGroupsOptions[0].value;
    }

    return [
      {
        name: "name",
        label: "Name",
        type: "textField",
        autoFocus: true,
        defaultValue: model ? model.name : "",
      },
      {
        name: "price",
        label: "Price",
        type: "number",
        autoFocus: false,
        defaultValue: model ? model.price : 0,
      },
      {
        name: "choiceGroupID",
        label: "Choice Group",
        type: "select",
        autoFocus: false,
        defaultValue: model ? model.choiceGroupID : defaultChoiceGroupID,
        options: choiceGroupsOptions,
      },
      {
        name: "symphonyID",
        label: "Simphony ID",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.symphonyID : "",
        // disabled: isStockManager(userPermissions),
      },
    ];
  };

  const choiceGroupFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "maxNumberOfChoices",
      label: "Max Number Of Choices",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.maxNumberOfChoices : "",
    },
    {
      name: "minNumberOfChoices",
      label: "Min Number Of Choices",
      type: "number",
      autoFocus: false,
      defaultValue: model ? model.minNumberOfChoices : "",
    },
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.precedence : "",
    },
    {
      name: "required",
      label: "Required",
      type: "checkbox",
      autoFocus: false,
      defaultValue: model ? model.required : "",
    },
  ];

  const orderStatusFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "color",
      label: "Color",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.color : "",
    },
    {
      name: "precedence",
      label: "Precedence",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.precedence : "",
    },
  ];

  const priceFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: true,
      defaultValue: model ? model.name : "",
    },
    {
      name: "price",
      label: "Price",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.price : "",
    },
    {
      name: "symphonyID",
      label: "Simphony ID",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.symphonyID : "",
    },
  ];

  const ParentConceptsFields = () => [
    {
      name: "name",
      label: "Name",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.name : "",
    },
    {
      name: "type",
      label: "Type",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.type : "",
    },
    {
      name: "index",
      label: "Index",
      type: "textField",
      autoFocus: false,
      defaultValue: model ? model.index : "",
    },
    {
      name: "image",
      label: "Image",
      type: "upload",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
    {
      name: "logo",
      label: "Logo",
      type: "upload",
      autoFocus: false,
      defaultValue: model ? model.logo : "",
    },
    {
      name: "venues",
      label: "Venues",
      type: "checkboxes",
      autoFocus: false,
      defaultValue: model ? new Set(model.venues) : new Set(),
      options: options,
    },
  ];

  const MobileNotificationsFields = () => {
    let defaultGroup = "";
    if (groupsOptions) {
      const group = groupsOptions.find(
        (item: any) => item.label === "normal" || item.label === "Normal"
      );
      defaultGroup = group ? group.value : "";
    }
    if (model) defaultGroup = model.group;

    return [
      {
        name: "default",
        label: "Default",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model ? model.default : true,
      },
      {
        name: "event",
        label: "Featured Banner",
        type: "checkbox",
        autoFocus: false,
        defaultValue: model ? model.event : false,
      },
      {
        name: "image",
        label: "Image",
        type: "upload",
        autoFocus: false,
        defaultValue: model ? model.image : "",
      },
      {
        name: "depositValue",
        label: "Deposit Amount",
        type: "number",
        autoFocus: false,
        defaultValue: model ? model.depositValue : 0,
      },
      {
        name: "eventDate",
        label: "Event Date",
        type: "date",
        autoFocus: false,
        defaultValue: model ? model.eventDate : "",
      },
      {
        name: "conceptID",
        label: "Venue",
        type: "select",
        autoFocus: false,
        defaultValue: model ? model.conceptID : "0",
        options: options,
      },
      {
        name: "title",
        label: "Notification Title",
        type: "textField",
        autoFocus: false,
        defaultValue: model ? model.title : "",
      },
      {
        name: "description",
        label: "Notification Description",
        type: "textField",
        autoFocus: false,
        multiline: true,
        defaultValue: model ? model.description : "",
      },
      {
        name: "notification body",
        label: "Notification Body",
        type: "textEditor",
        autoFocus: true,
        defaultValue: model ? model.body : "",
      },
    ];
  };

  const getPayMobFields = (
    numToUpgradeWatch?: any,
    numToDowngradeWatch?: any,
    channelsWatch?: any
  ) => {
    return payMobFields();
  };

  const getFormFields = (
    numToUpgradeWatch?: any,
    numToDowngradeWatch?: any,
    channelsWatch?: any
  ) => {
    switch (slug) {
      case Pages.LOGIN:
        return loginFields();
      case Pages.REGISTER:
        return registerFields();
      case Pages.ADMINS:
        return adminFields();
      case Pages.WAITERS: //
        return adminFields();
      case Pages.FORGOT_PASSWORD:
        return forgetPasswordFields();
      case Pages.RESET_PASSWORD:
        return resetPasswordFields();
      case Pages.ACCOUNTS:
        return accountFields();
      case Pages.AREAS:
        return areaFields();
      case Pages.CONCEPTS:
        return conceptFields();
      case Pages.FEATURES:
        return featureFields();
      case Pages.LANGUAGES:
        return languageFields();
      case Pages.DISPATCHERS:
        return dispatchersFields();
      case Pages.SOCIAL_LINKS:
        return socialLinkFields();
      case Pages.GROUPS:
        return groupFields(numToUpgradeWatch, numToDowngradeWatch);
      case Pages.RESERVATION_STATUS:
        return statusFields();
      case Pages.FLAGS:
        return flagFields();
      case Pages.INTERESTS:
        return interestFields();
      case Pages.GROUP_SETTINGS:
        return groupSettingFields();
      case Pages.PLAN_ITEMS:
        return planItemFields();
      case Pages.GUESTS:
        return userFields();
      case Pages.CALL_CENTER_SETTINGS:
        return callCenterFields();
      case Pages.TABLES:
        return tableFields();
      case Pages.TIME_SLOTS:
        return timeSlotFields();
      case Pages.BOOKING_CONFIRMATION:
        return bookingConfirmationFields();
      case Pages.NOTIFICATIONS:
        return notificationsFields(channelsWatch);
      case Pages.ADMIN_ROLES:
        return adminRoleFields();
      case Pages.ADMIN_GROUPS:
        return adminGroupsFields();
      case Pages.TRANSACTIONS_REPORT:
        return transactionFields();
      case Pages.PAYMENT_STATS:
        return paymentStatFields();
      case Pages.BOOKING_GUEST:
        return bookingGuestFields();
      case Pages.Mobile_NOTIFICATIONS:
        return MobileNotificationsFields();
      case Pages.Parent_Concepts:
        return ParentConceptsFields();
      case Pages.CATEGORIES:
        return categoryFields();
      case Pages.MAIN_CATEGORIES:
        return mainCategoryFields();
      case Pages.SHIFTS:
        return shiftFields();
      case Pages.MENU_ITEMS:
        return menuItemFields();
      case Pages.CHOICES:
        return choiceFields();
      case Pages.CHOICE_GROUPS:
        return choiceGroupFields();
      case Pages.ORDER_STATUSES:
        return orderStatusFields();
      case Pages.PRICES:
        return priceFields();

      default:
        return [];
    }
  };

  const getCheckboxesFields = () => {
    switch (slug) {
      case Pages.ACCOUNTS:
        return accountCheckboxes();
      case Pages.GUESTS:
        return userCheckboxes();
      case Pages.ADMIN_GROUPS:
        return adminGroupsCheckboxes();
      case Pages.MENU_ITEMS:
        return menuItemCheckboxes();
      case Pages.CHOICE_GROUPS:
        return choiceGroupsCheckboxes();
      default:
        return [];
    }
  };

  const generalImages = () => [
    {
      name: "image",
      label: "Image",
      type: "image",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
  ];
  const accountsImages = () => [
    {
      name: "sponsorImage",
      label: "Sponsor Image",
      type: "image",
      autoFocus: false,
      defaultValue: model ? model.image : "",
    },
  ];

  const getImageFields = () => {
    switch (slug) {
      case Pages.MENU_ITEMS:
      case Pages.CATEGORIES:
      case Pages.TABLES:
        return generalImages();

      default:
        return [];
    }
  };

  return {
    getFormFields,
    getPayMobFields,
    getCheckboxesFields,
    getImageFields,
  };
};

export default useFormFields;
