// actions
const SET = "users/SET";
const SET_SELECTED = "users/SET_SELECTED";
const SET_SEARCHING = "users/SET_SEARCHING";
const SET_NEXT_TOKEN = "guests/SET_NEXT_TOKEN";
const SET_PREV_NEXT_TOKEN = "guests/SET_PREV_NEXT_TOKEN";

const DEFAULT_STATE = {
  listing: [],
  selected: "",
  isSearching: false,

  nextToken: null,
  prevNextToken: "",
  nextNextToken: undefined,
  previousTokens: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SELECTED:
      return Object.assign({}, state, { selected: action.id });
    case SET_SEARCHING:
      return Object.assign({}, state, { isSearching: action.payload });
    case SET_NEXT_TOKEN:
      return Object.assign({}, state, { nextToken: action.id });
    case SET_PREV_NEXT_TOKEN:
      return Object.assign({}, state, { prevNextToken: action.id });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setSelected = (id: string) => ({ id, type: SET_SELECTED });
export const setIsSearching = (payload: boolean) => ({
  payload,
  type: SET_SEARCHING,
});
export const setNextToken = (id: any) => ({ id, type: SET_NEXT_TOKEN });
export const setPrevNextToken = (id: any) => ({
  id,
  type: SET_PREV_NEXT_TOKEN,
});
