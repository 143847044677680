/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://pk1nd6ckbe.execute-api.us-east-2.amazonaws.com/burak",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://b6q45pp4bzewphmhahpnhhbuiu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-anyhufcb5fbsphjg5rw2cqc5km",
    "aws_cognito_identity_pool_id": "us-east-2:1d38e516-5b77-4dda-b60f-32479e167d43",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_q3DSpV8dG",
    "aws_user_pools_web_client_id": "6broaeeioqoasf81hdedm6028v",
    "oauth": {
        "domain": "hospitalityofflineaa851aa7-aa851aa7-burak.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "software.anyware.baky://",
        "redirectSignOut": "software.anyware.baky://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hospitalityassets161655-burak",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
